html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

.rmsc {
  --rmsc-radius: 25px!important; /* Radius */
  --rmsc-border: #0055a0!important;
}

.react-datepicker__input-container {
  border: 1px solid #0055a0;
  border-radius: 25px;
  padding: 5px 10px;
  outline: none;
}


.react-datepicker__input-container > input {
  width: 100%
}

.react-datepicker-ignore-onclickoutside:focus,
.react-datepicker-ignore-onclickoutside:focus-visible {
  outline: none;
}

.toggleWrapper {
  display: flex;
  align-items: center;
}
.toggleWrapper input.mobileToggle {
  opacity: 0;
  position: absolute;
}
.toggleWrapper input.mobileToggle + label {
  position: relative;
  display: inline-block;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: 0.4s ease;
  height: 30px;
  width: 50px;
  border: 1px solid #e4e4e4;
  border-radius: 60px;
}
.toggleWrapper input.mobileToggle + label:before {
  content: "";
  position: absolute;
  display: block;
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  height: 30px;
  width: 51px;
  top: 0;
  left: 0;
  border-radius: 30px;
}
.toggleWrapper input.mobileToggle + label:after {
  content: "";
  position: absolute;
  display: block;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 0px 0 hsla(0, 0%, 0%, 0.04), 0 4px 9px hsla(0, 0%, 0%, 0.13), 0 3px 3px hsla(0, 0%, 0%, 0.05);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  background: whitesmoke;
  height: 28px;
  width: 28px;
  top: 1px;
  left: 0px;
  border-radius: 60px;
}
.toggleWrapper input.mobileToggle:checked + label:before {
  background: #2ecc71;
  transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
}
.toggleWrapper input.mobileToggle:checked + label:after {
  left: 24px;
}
@media screen and (max-width: 768px) {
  .min-h-mobile {
    min-height: 60%;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
